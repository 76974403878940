.ob-app-btn {
  text-align: center;

  &.ob-large {
    width: $app-btn-large-label-width;

    .ob-btn-icon {
      width: $app-btn-diameter-large;
      height: $app-btn-diameter-large;
    }
  }

  &.ob-normal {
    width: $app-btn-diameter-normal;

    .ob-btn-icon {
      width: $app-btn-diameter-normal;
      height: $app-btn-diameter-normal;
    }
  }

  .ob-btn-icon {
    @include style-raised($app-btn-diameter-normal);
    @include pointer-on-hover-not-disabled();
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
  }

  .ob-label {
    @include font-ui-label;
    display: inline;
    width: $app-btn-large-label-width;
    height: $app-btn-large-label-height;

    text-align: center;
  }
}
