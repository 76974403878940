.ob-button-group {
  display: inline-flex;
  height: $btn-height-normal;
  background-color: $container-backdrop-color;
  border-color: $indent-enabled-border-color;
  border-width: $indent-enabled-border-width;
  border-radius: $btn-height-normal;
}

.ob-button-group__item {
  position: relative;
  display: inline-flex;
  height: $btn-height-normal;

  & > .ob-text-under {
    @include font-ui-label();
    @include color-flat-neutral;
    position: absolute;
    left: 0;
    width: 100%;

    text-align: center;

    transform: translateY($btn-height-normal + $btn-text-under-spacing);
  }
}

.ob-button-group__item--selected > .ob-text-under {
    @include font-ui-label-active();
    @include color-flat-active;
  }


.ob-button-group__button {
  border-radius: $btn-height-normal;
  @include style-flat($btn-height-small);
  @include color-flat-neutral;
}

.ob-button-group__item--selected > .ob-button-group__button {
  @include style-normal($btn-height-small);
  @include color-normal-active;
}
