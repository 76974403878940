:root[theme=dusk] {
  --element-active-color: rgb(255, 255, 255);
  --element-neutral-color: rgba(255, 255, 255, .500);
  --element-disabled-color: rgba(255, 255, 255, .200);
  --element-active-inverted-color: rgb(0, 0, 0);
  --element-neutral-inverted-color: rgba(0, 0, 0, .600);
  --element-disabled-inverted-color: rgba(0, 0, 0, .300);
  --container-backdrop-color: rgb(51, 51, 51);
  --container-section-color: rgb(59, 59, 59);
  --container-background-color: rgb(64, 64, 64);
  --container-global-color: rgb(77, 77, 77);
  --border-divider-color: rgba(255, 255, 255, .120);
  --border-outline-color: rgba(0, 0, 0, .400);
  --border-solid-color: rgb(255, 255, 255);
  --instrument-dynamic-color: rgb(51, 187, 255);
  --instrument-static-color: rgb(255, 255, 255);
  --instrument-input-color: rgb(255, 200, 0);
  --instrument-port-color: rgb(204, 0, 61);
  --instrument-starboard-color: rgb(0, 151, 97);
  --instrument-frame-color: rgb(56, 56, 56);
  --instrument-track-color: rgb(89, 89, 89);
  --instrument-tick-mark-primary-color: rgb(255, 255, 255);
  --instrument-tick-mark-secondary-color: rgb(153, 153, 153);
  --instrument-tick-mark-tertiary-color: rgb(77, 77, 77);
  --alert-running-color: rgb(0, 160, 0);
  --alert-caution-color: rgb(255, 213, 0);
  --alert-warning-color: rgb(255, 140, 0);
  --alert-alarm-color: rgb(204, 0, 0);
  --alert-critical-alarm-color: rgb(254, 0, 255);
  --normal-enabled-background-color: rgb(89, 89, 89);
  --normal-enabled-border-color: rgba(255, 255, 255, .050);
  --normal-hover-background-color: rgb(102, 102, 102);
  --normal-hover-border-color: rgba(255, 255, 255, .050);
  --normal-pressed-background-color: rgb(128, 128, 128);
  --normal-pressed-border-color: rgba(255, 255, 255, .200);
  --normal-focused-background-color: rgb(89, 89, 89);
  --normal-focused-border-color: rgb(255, 197, 0);
  --normal-disabled-background-color: rgba(255, 255, 255, .00);
  --normal-disabled-border-color: rgba(255, 255, 255, .100);
  --on-normal-active-color: rgb(255, 255, 255);
  --on-normal-neutral-color: rgba(255, 255, 255, .500);
  --on-normal-disabled-color: rgba(255, 255, 255, .200);
  --raised-enabled-background-color: rgba(255, 255, 255, .300);
  --raised-enabled-border-color: rgba(255, 255, 255, .050);
  --raised-hover-background-color: rgba(255, 255, 255, .400);
  --raised-hover-border-color: rgba(255, 255, 255, .050);
  --raised-pressed-background-color: rgba(255, 255, 255, .500);
  --raised-pressed-border-color: rgba(255, 255, 255, .200);
  --raised-focused-background-color: rgba(255, 255, 255, .400);
  --raised-focused-border-color: rgb(255, 197, 0);
  --raised-disabled-background-color: rgba(255, 255, 255, .00);
  --raised-disabled-border-color: rgba(255, 255, 255, .100);
  --on-raised-active-color: rgb(255, 255, 255);
  --on-raised-neutral-color: rgba(255, 255, 255, .500);
  --on-raised-disabled-color: rgba(255, 255, 255, .200);
  --indent-enabled-background-color: rgba(0, 0, 0, .200);
  --indent-enabled-border-color: rgba(0, 0, 0, .00);
  --indent-hover-background-color: rgba(0, 0, 0, .400);
  --indent-hover-border-color: rgba(0, 0, 0, .00);
  --indent-pressed-background-color: rgba(0, 0, 0, .600);
  --indent-pressed-border-color: rgba(0, 0, 0, .600);
  --indent-focused-background-color: rgba(0, 0, 0, .200);
  --indent-focused-border-color: rgb(255, 197, 0);
  --indent-disabled-background-color: rgba(0, 0, 0, .00);
  --indent-disabled-border-color: rgba(255, 255, 255, .100);
  --on-indent-active-color: rgb(255, 255, 255);
  --on-indent-neutral-color: rgba(255, 255, 255, .500);
  --flat-enabled-background-color: rgba(255, 255, 255, .00);
  --flat-enabled-border-color: rgba(255, 255, 255, .00);
  --flat-hover-background-color: rgba(255, 255, 255, .100);
  --flat-hover-border-color: rgba(255, 255, 255, .00);
  --flat-pressed-background-color: rgba(255, 255, 255, .200);
  --flat-pressed-border-color: rgba(255, 255, 255, .00);
  --flat-focused-background-color: rgba(0, 0, 0, .00);
  --flat-focused-border-color: rgb(255, 200, 0);
  --flat-disabled-background-color: rgba(0, 0, 0, .00);
  --flat-disabled-border-color: rgba(255, 255, 255, .00);
  --on-flat-active-color: rgb(255, 255, 255);
  --on-flat-disabled-color: rgba(255, 255, 255, .200);
  --selected-enabled-background-color: rgb(204, 204, 204);
  --selected-enabled-border-color: rgba(255, 255, 255, .00);
  --selected-hover-background-color: rgb(179, 179, 179);
  --selected-hover-border-color: rgba(255, 255, 255, .00);
  --selected-pressed-background-color: rgb(153, 153, 153);
  --selected-pressed-border-color: rgba(255, 255, 255, .200);
  --selected-focused-background-color: rgb(204, 204, 204);
  --selected-focused-border-color: rgb(255, 197, 0);
  --selected-disabled-background-color: rgba(255, 255, 255, .100);
  --selected-disabled-border-color: rgba(255, 255, 255, .00);
  --on-selected-active-color: rgb(0, 0, 0);
  --on-selected-neutral-color: rgba(0, 0, 0, .700);
  --on-selected-disabled-color: rgba(255, 255, 255, .200);
  --thumb-enabled-background-color: rgb(255, 255, 255);
  --thumb-enabled-border-color: rgba(0, 0, 0, .600);
  --thumb-hover-background-color: rgb(204, 204, 204);
  --thumb-hover-border-color: rgba(0, 0, 0, .600);
  --thumb-pressed-background-color: rgb(153, 153, 153);
  --thumb-pressed-border-color: rgba(0, 0, 0, .600);
  --thumb-focused-background-color: rgb(255, 255, 255);
  --thumb-focused-border-color: rgb(255, 197, 0);
  --thumb-disabled-background-color: rgba(0, 0, 0, .300);
  --thumb-disabled-border-color: rgba(0, 0, 0, .00);
  --on-thumb-active-color: rgb(0, 0, 0);
  --on-thumb-neutral-color: rgba(0, 0, 0, .700);
  --on-thumb-disabled-color: rgba(255, 255, 255, .200);
  --amplified-enabled-background-color: rgb(89, 89, 89);
  --amplified-enabled-border-color: rgba(0, 0, 0, .400);
  --amplified-hover-background-color: rgb(102, 102, 102);
  --amplified-hover-border-color: rgba(0, 0, 0, .400);
  --amplified-pressed-background-color: rgb(128, 128, 128);
  --amplified-pressed-border-color: rgba(255, 255, 255, .200);
  --amplified-focused-background-color: rgb(89, 89, 89);
  --amplified-focused-border-color: rgb(255, 200, 0);
  --amplified-disabled-background-color: rgba(255, 255, 255, .100);
  --amplified-disabled-border-color: rgba(255, 255, 255, .00);
  --on-amplified-active-color: rgb(255, 255, 255);
  --on-amplified-neutral-color: rgba(255, 255, 255, .500);
  --on-amplified-disabled-color: rgba(255, 255, 255, .200);
  --running-enabled-background-color: rgb(0, 153, 0);
  --running-enabled-border-color: rgba(0, 0, 0, .100);
  --running-hover-background-color: rgb(0, 127, 0);
  --running-hover-border-color: rgba(0, 0, 0, .100);
  --running-pressed-background-color: rgb(0, 102, 0);
  --running-pressed-border-color: rgba(0, 0, 0, .300);
  --running-focused-background-color: rgb(0, 153, 0);
  --running-focused-border-color: rgb(0, 0, 0);
  --running-disabled-background-color: rgb(0, 153, 0);
  --running-disabled-border-color: rgba(0, 0, 0, .00);
  --on-running-active-color: rgb(0, 0, 0);
  --on-running-neutral-color: rgba(0, 0, 0, .700);
  --on-running-disabled-color: rgba(0, 0, 0, .300);
  --caution-enabled-background-color: rgb(255, 213, 0);
  --caution-enabled-border-color: rgba(0, 0, 0, .100);
  --caution-hover-background-color: rgb(204, 170, 0);
  --caution-hover-border-color: rgba(0, 0, 0, .200);
  --caution-pressed-background-color: rgb(153, 128, 0);
  --caution-pressed-border-color: rgba(0, 0, 0, .300);
  --caution-focused-background-color: rgb(255, 213, 0);
  --caution-focused-border-color: rgb(0, 0, 0);
  --caution-disabled-background-color: rgb(255, 213, 0);
  --caution-disabled-border-color: rgba(0, 0, 0, .00);
  --on-caution-active-color: rgb(0, 0, 0);
  --on-caution-neutral-color: rgba(0, 0, 0, .700);
  --on-caution-disabled-color: rgba(0, 0, 0, .300);
  --warning-enabled-background-color: rgb(255, 140, 0);
  --warning-enabled-border-color: rgba(0, 0, 0, .100);
  --warning-hover-background-color: rgb(204, 112, 0);
  --warning-hover-border-color: rgba(0, 0, 0, .200);
  --warning-pressed-background-color: rgb(153, 84, 0);
  --warning-pressed-border-color: rgba(0, 0, 0, .400);
  --warning-focused-background-color: rgb(255, 140, 0);
  --warning-focused-border-color: rgb(0, 0, 0);
  --warning-disabled-background-color: rgb(255, 140, 0);
  --warning-disabled-border-color: rgba(0, 0, 0, .00);
  --on-warning-active-color: rgb(0, 0, 0);
  --on-warning-neutral-color: rgba(0, 0, 0, .700);
  --on-warning-disabled-color: rgba(0, 0, 0, .300);
  --alarm-enabled-background-color: rgb(204, 0, 0);
  --alarm-enabled-border-color: rgba(0, 0, 0, .100);
  --alarm-hover-background-color: rgb(153, 0, 0);
  --alarm-hover-border-color: rgba(0, 0, 0, .200);
  --alarm-pressed-background-color: rgb(102, 0, 0);
  --alarm-pressed-border-color: rgba(0, 0, 0, .400);
  --alarm-focused-background-color: rgb(204, 0, 0);
  --alarm-focused-border-color: rgb(0, 0, 0);
  --alarm-disabled-background-color: rgb(204, 0, 0);
  --alarm-disabled-border-color: rgba(0, 0, 0, .00);
  --on-alarm-active-color: rgb(0, 0, 0);
  --on-alarm-neutral-color: rgba(0, 0, 0, .700);
  --on-alarm-disabled-color: rgba(0, 0, 0, .300);
  --critical-alarm-enabled-background-color: rgb(254, 0, 255);
  --critical-alarm-enabled-border-color: rgba(0, 0, 0, .100);
  --critical-alarm-hover-background-color: rgb(203, 0, 204);
  --critical-alarm-hover-border-color: rgba(0, 0, 0, .200);
  --critical-alarm-pressed-background-color: rgb(152, 0, 153);
  --critical-alarm-pressed-border-color: rgba(0, 0, 0, .400);
  --critical-alarm-focused-background-color: rgb(254, 0, 255);
  --critical-alarm-focused-border-color: rgb(0, 0, 0);
  --critical-alarm-disabled-background-color: rgb(254, 0, 255);
  --critical-alarm-disabled-border-color: rgba(0, 0, 0, .00);
  --on-critical-alarm-active-color: rgb(0, 0, 0);
  --on-critical-alarm-neutral-color: rgba(0, 0, 0, .700);
  --on-critical-alarm-disabled-color: rgba(0, 0, 0, .300);
  --font-ui-title-size: 24px;
  --font-ui-title-weight: bold;
  --font-ui-title-line-height: 32px;
  --font-ui-subtitle-size: 24px;
  --font-ui-subtitle-weight: regular;
  --font-ui-subtitle-line-height: 32px;
  --font-ui-overline-size: 12px;
  --font-ui-overline-weight: semibold;
  --font-ui-overline-line-height: 24px;
  --font-ui-button-size: 16px;
  --font-ui-button-weight: semibold;
  --font-ui-button-line-height: 24px;
  --font-ui-body-active-size: 16px;
  --font-ui-body-active-weight: bold;
  --font-ui-body-active-line-height: 24px;
  --font-ui-body-size: 16px;
  --font-ui-body-weight: regular;
  --font-ui-body-line-height: 24px;
  --font-ui-label-active-size: 12px;
  --font-ui-label-active-weight: bold;
  --font-ui-label-active-line-height: 24px;
  --font-ui-label-size: 12px;
  --font-ui-label-weight: regular;
  --font-ui-label-line-height: 24px;
  --font-instrument-label-s-size: 16px;
  --font-instrument-label-s-weight: regular;
  --font-instrument-label-s-line-height: undefinedpx;
  --font-instrument-label-m-size: 24px;
  --font-instrument-label-m-weight: regular;
  --font-instrument-label-m-line-height: 42px;
  --font-instrument-label-l-size: 32px;
  --font-instrument-label-l-weight: regular;
  --font-instrument-label-l-line-height: 64px;
  --font-instrument-value-s-size: 16px;
  --font-instrument-value-s-weight: semibold;
  --font-instrument-value-s-line-height: undefinedpx;
  --font-instrument-value-s-enhanced-size: 24px;
  --font-instrument-value-s-enhanced-weight: semibold;
  --font-instrument-value-s-enhanced-line-height: undefinedpx;
  --font-instrument-value-m-size: 32px;
  --font-instrument-value-m-weight: semibold;
  --font-instrument-value-m-line-height: 48px;
  --font-instrument-value-m-enhanced-size: 48px;
  --font-instrument-value-m-enhanced-weight: semibold;
  --font-instrument-value-m-enhanced-line-height: 64px;
  --font-instrument-value-l-size: 64px;
  --font-instrument-value-l-weight: semibold;
  --font-instrument-value-l-line-height: 88px;
  --font-instrument-value-l-enhanced-size: 80px;
  --font-instrument-value-l-enhanced-weight: semibold;
  --font-instrument-value-l-enhanced-line-height: 104px;
  --font-instrument-unit-s-size: 16px;
  --font-instrument-unit-s-weight: regular;
  --font-instrument-unit-s-line-height: undefinedpx;
  --font-instrument-unit-m-size: 24px;
  --font-instrument-unit-m-weight: regular;
  --font-instrument-unit-m-line-height: 42px;
  --font-instrument-unit-l-size: 32px;
  --font-instrument-unit-l-weight: regular;
  --font-instrument-unit-l-line-height: 64px;
  --font-instrument-status-s-size: 16px;
  --font-instrument-status-s-weight: regular;
  --font-instrument-status-s-line-height: undefinedpx;
  --font-instrument-status-m-size: 24px;
  --font-instrument-status-m-weight: regular;
  --font-instrument-status-m-line-height: 42px;
  --font-instrument-status-l-size: 32px;
  --font-instrument-status-l-weight: regular;
  --font-instrument-status-l-line-height: 64px;
}